import React from "react";
import { Lesson } from "../../../api/Lesson/Lesson";

interface IExcelReport {
	report: Lesson[];
}

const ExcelReport = ({ report }: IExcelReport) => {
	return (
<div></div>
	);
};

export default ExcelReport;
